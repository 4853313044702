$breakpointL: 768px;

.ContactForm {
    width: 100%;
    max-width: var(--content-width);
    border: 1px solid var(--white);
    border-radius: 12px;
    min-height: 440px;

    .form {
        display: flex;
        @media(max-width: $breakpointL){
            flex-wrap: wrap;
        }

        .content{
            width: 50%;
            display: flex;
            flex-direction: column;
    
            @media(max-width: $breakpointL){
                width: 100%;
            }
    
            .text {
                padding: 27px; 

                @media(max-width: $breakpointL){
                    padding: 20px;
                }
    
                h6 {
                    font-size: var(--h2-font-size);
                    margin: 0 0 20px;
                    color: var(--white);
                }
    
                p{
                    font-family: var(--font-family-content);
                    color: var(--white);
                    margin-bottom: 40px;

                    @media(max-width: $breakpointL){
                        margin-bottom: 10px;
                    }
                }
            }
    
            .footerAnimation{
                position: relative;
                width: 100%;
                height: calc(100% - 200px);
                overflow: hidden;
                border-radius: 0 0 0 12px;
                background-image: url(../../assets/footer-pattern.png);
                background-size: cover;
                border-top: 1px solid var(--white);
                @media(max-width: $breakpointL){
                    display: none;
                }

            }
        }
        .formWrap {
            width: 50%;
            background-color: var(--white);
            border-radius: 0 12px 12px 0;
            padding: 40px 35px;
            box-sizing: border-box;
    
            @media(max-width: $breakpointL){
                width: 100%;
                padding: 20px;
                border-radius: 0 0 12px 12px;
            }
    
            .textFieldWrap {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
    
                > div {
                    width: calc(50% - 12px);
    
                    @media(max-width: $breakpointL){
                        width: 100%;
                    }
                    
                    .textField{
                        margin-bottom: 40px;
                        position: relative;
                        
                    }
                }
            }
    
            p{
                margin: 0;
                font-size: 0.75rem;
            }
    
            .agreementChecked {
                margin-top: 20px;
            }
    
            .submitLine {
                margin-top: 20px;
                display: flex;
                justify-content: flex-end;
                @media(max-width: $breakpointL){
                    justify-content: center;
                }
                button {
                    background-color:  var(--accent-color-blue);
                    height: 48px;
                    width: 166px;
                    border-radius: 50px;
                    transition: all 0.2s ease-in-out;
    
                    &:hover{
                        opacity: 0.86;
                        transition: all 0.2s ease-in-out;
                    }

                    &:disabled {
                        background-color: var(--neutral400);
                        cursor: not-allowed;
                        color: white
                    }
                }
            }
        }
    }

    .thankYouMessageWrap {
        display: flex;

        .animation{
            position: relative;
            width: 100%;
            height: inherit;
            overflow: hidden;
            background-image: url(../../assets/footer-pattern.png);
            background-size: cover;
            border-radius: 12px 0 0 12px;

            @media(max-width: $breakpointL){
                display: none;
            }

            @media (max-width: $breakpointL){
                border-radius: 12px;
            }
          
            video {
              object-fit: cover;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
            }
        }

        .thankYouMessage {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            border-radius: 0 12px 12px 0;
            padding: 50px;
            background-color: var(--white);
            text-align: center;
            position: relative;
            
            h6 {
                font-size: 24px;
                margin: 0 0 20px;
                color: var(--accent-color-blue);
                margin-bottom: 34px;
                font-weight: 400;
            }

            p {
                font-size: 24px;
                color: var(--neutral900);
                text-align: center;
                font-weight: 500;
                line-height: 140%;
                margin-bottom: 50px;

                &.blueText {
                    font-size: 24px;
                    color: var(--accent-color-blue);
                    font-weight: 400;
                }
            }

            .button {
                background-color:  var(--accent-color-blue);
                height: 48px;
                border-radius: 50px;
                transition: all 0.2s ease-in-out;

                &:hover{
                    opacity: 0.86;
                    transition: all 0.2s ease-in-out;
                }
            }

            .closeBtn{
                position: absolute;
                top:15px;
                right:15px;
                color: var(--neutral900);
                font-size: 1rem;
                min-width: 30px;
                height: 30px;
            }
        }
    }

}
$breakpointSm: 576px;
$breakpointLg: 992px;
$breakpointXl: 1250px;

.headerWrapper {
    width: 100%;
    background-color: var(--neutral900);
    display: flex;
    justify-content: center;
}

.textWrap {
    padding: 120px 20px 90px;
    display: flex;
    align-items: center;


    @media (max-width: $breakpointLg){
        padding: 70px 20px;

        h2{
            margin: 0;
        }
    }
}

.accordionWrap {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 100px;
    max-width: var(--content-width);
    padding: var(--layout-padding);

    @media (max-width: $breakpointLg){
        margin-bottom: 70px;
    }
}
.tabsWrapper{
    display: flex;
    justify-content: flex-end;
    box-sizing: border-box;
    width: 100%;
    max-width: var(--content-width);
    padding: var(--layout-padding);
}



.tabsWrap{
    background-color: var(--neutral900);
    border-radius: 12px;
    width: calc(100% - 40px);
    max-width: 729px;
    margin-bottom: 110px;
    position: relative;

    @media (max-width: $breakpointXl){
      margin-bottom: 70px;
    }

    @media (max-width: $breakpointLg){
        max-width: 100%;
        width: 100%;
    }

    .arrow{
        width: 156px;
        height: 216px;
        border-left: 1px solid var(--neutral900);
        border-bottom: 1px solid var(--neutral900);
        position: absolute;
        right: 100%;
        top:-100px;
        border-radius: 0 0 0 12px;

        @media (max-width: $breakpointXl){
            height: 205px;
          }

        @media (max-width: $breakpointLg){
            height: 70px;
            width: 0;
            left: 30px;
            top:-71px;

            &::after {
                transform: rotate(90deg);
                bottom: -7.5px;
                right: -2.6px;
            }
        }
    }

    h2{
        font-size:  var(--h2-font-size);
        line-height: var(--h2-line-height);
        color: var(--white);
        font-weight: 700;
        margin: 0;
        padding: 30px 40px;
        border-bottom: 1px solid var(--white);

        @media (max-width: $breakpointSm){
          padding: 20px;
        }
    }

    .contentWrap {
        display: flex;

        @media (max-width: $breakpointLg) {
          flex-direction: column;
          flex-wrap: wrap;
        }

        .steps {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 15px;
          border-right: 1px solid var(--white);

          @media (max-width: $breakpointXl) {
            border-left: 1px solid var(--white);
          }

          @media (max-width: $breakpointLg) {
            display: none;
          }

          p{
            color: var(--white);
            text-wrap: nowrap;
            padding: 0;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            margin: 15px 0 0 0;
          }
          
          div {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              border: 1px solid var(--neutral900);
              display: flex;
              align-items: center;
              justify-content: center;
              background: var(--neutral300);
              img{
                transition: all 0.2s ease-in-out;
              }
          }
      
      
        }

        .tabMobileButtons{
          display: none;

          @media (max-width: $breakpointSm){
            display: flex;
            justify-content: space-between;
            padding:20px 20px 0;
            align-items: center;
            border-bottom: 1px solid var(--white);
            padding-bottom: 20px;

            h6{
              font-size: 1rem;
              font-weight: 400;
              margin: 0;
              color: var(--white)
            }

            > div {
              display: flex; 
              align-items: center;

            }
  
            button {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              border: 1px solid var(--white);
              align-items: center;
              justify-content: center;
              background-color: var(--white);
              cursor: pointer;
              transition: all 0.3s ease-in-out;
  
              &:hover{
                opacity: 0.75;
                transition: all 0.3s ease-in-out;
              }

              &:disabled{
                background-color: var(--neutral200);
                opacity: 0.5;

                  &:hover{
                    background-color: var(--neutral200);
                  }
                }
              
  
              &.prev{
                margin-right:20px;
                transform: rotate(90deg);
              }
  
              &.next{
                transform: rotate(-90deg);
              }
            }
          }


        }
    
        .tabsContainer {
          min-width: 620px;
          width: 100%;
          padding: 30px;
          height: 320px;
          box-sizing: border-box;

          @media (max-width: $breakpointSm){
            padding: 20px;
          }

          @media (max-width: $breakpointXl) {
            min-width: auto;
            height: auto;
          }
    
          .tabs {
            display: flex;
            margin-bottom: 24px;
            justify-content: space-between;
            position: relative;
    
            @media (max-width: $breakpointSm){
              display: none;
            }

            &::after {
              content: '';
              width: 100%;
              position: absolute;
              height: 1px;
              background-color: var(--white);
              top: 50%;
              z-index: 1;
            }
          }

          .tab {
            cursor: pointer;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            border:1px solid var(--white);
            color: var(--white);
            background-color: var(--neutral900);
            outline: 8px solid var(--neutral900);
            transition: all 0.2s ease-in-out;
            position: relative;
            z-index: 2;
    
            &:hover {
            border:1px solid transparent;
              background-color: var(--accent-color-blue);
              transition: all 0.2s ease-in-out;
            }
          }
          
          .tab.active {
            border:1px solid transparent;
            background-color: var(--accent-color-blue);
          }
          
          .tabContent {
            color: var(--white);
            @media (max-width: $breakpointSm){
              > div{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
              }
            }

            span{
              display: none;
                @media (max-width: $breakpointSm){
                  width: 45px;
                  height: 45px;
                  border-radius: 50%;
                  border: 1px solid var(--white);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: var(--white);
                  background-color: var(--accent-color-blue);
                  margin-right: 20px;   
                }          
            }

            h6{
              font-size: 1.5rem;
              font-weight: 500;
              font-family: var(--font-family-content);
              margin: 0 0 16px 0;

              @media (max-width: $breakpointSm){
                width: calc(100% - 65px);
                margin: 0;
              }
            }
            p{
              margin: 0 0 25px 0;
              font-family: var(--font-family-content);
              color: var(--white);
            }
          }
        }
      }
}
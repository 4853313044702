$breakpointSm: 576px;
$breakpointLg: 992px;
$breakpointXl: 1250px;

.headerWrapper {
    width: 100%;
    background-color: var(--neutral900);
    display: flex;
    justify-content: center;
}

.textWrap {
    padding: 120px 20px 90px;
    display: flex;
    align-items: center;

    @media (max-width: $breakpointXl){
      padding: 70px 20px;

      h2{
          margin: 0;
      }
    }
}

.accordionWrap {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 100px;
    max-width: var(--content-width);
    padding: var(--layout-padding);
}
.tabsWrap{
    border: 1px solid var(--neutral900);
    border-radius: 12px;
    width: calc(100% - 40px);
    max-width: var(--content-width);
    margin: 0 20px 110px;

    @media (max-width: $breakpointXl){
      margin-bottom: 70px;
    }

    h2{
        font-size:  var(--h2-font-size);
        line-height: var(--h2-line-height);
        font-weight: 700;
        margin: 0;
        padding: 30px 40px;
        border-bottom: 1px solid var(--neutral900);

        @media (max-width: $breakpointSm){
          padding: 20px;
        }
    }

    .contentWrap {
        display: flex;

        @media (max-width: $breakpointLg) {
          flex-direction: column;
          flex-wrap: wrap;
        }

        .list {
          width: 100%;
          min-width: 300px;
         ul{
          padding: 0;
          margin: 30px 25px;

          @media (max-width: $breakpointSm) {
            margin: 20px
          }
          li{
            list-style: none;
            position: relative;
            padding-left:15px;
            font-size: 1.25rem;
            line-height: 1.6rem;
            font-family: var(--font-family-content);

            @media (max-width: $breakpointSm) {
              font-size: 1rem;
            }

            &::after{
              content: url(../../assets/li1.svg);
              position: absolute;
              left: 0;
              top: -1px;
            }


            &:nth-child(2){
              &::after{
                content: url(../../assets/li2.svg);
              }
            }

            &:nth-child(3){
              &::after{
                content: url(../../assets/li4.svg);
              }
            }

            &:nth-child(4){
              &::after{
                content: url(../../assets/li3.svg);
              }
            }

            &:nth-child(5){
              &::after{
                content: url(../../assets/li5.svg);
                top: 1px;
              }
            }

            &:nth-child(6){
              &::after{
                content: url(../../assets/li3.svg);
                top: 0px;
              }
            }

            &:nth-child(7){
              &::after{
                content: url(../../assets/li3.svg);
                top: 0px;

              }
            }

            &:nth-child(8){
              &::after{
                content: url(../../assets/li5.svg);
                top: 1px;

              }
            }

          }
        }
        }

        .pattern {
          width: 100%;
          max-width: 142px;
          background-image: url(../../assets/pattern.svg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-left: 1px solid var(--neutral900);
          border-right: 1px solid var(--neutral900);
          position: relative;



          &::after {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            bottom: 2px;
            left: -6px;
            transform: rotate(-45deg);
            border-left: 1px solid var(--neutral900);
            border-bottom: 1px solid var(--neutral900);
          }

          @media (max-width: $breakpointXl) {
            display: none;
          }

          @media (max-width: $breakpointLg) {
            width: 100%;
            height: 142px;
            max-width: 100%;
            border: none;

            &::after{
              display: none;
            }
          }

          @media (max-width: $breakpointSm){
            display: flex;
            background-image: url(../../assets/pattern-mobile.svg);
            border-top: 1px solid var(--neutral900);
            border-bottom: 1px solid var(--neutral900);
          }

        }

        .steps {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          padding: 15px;
          border-right: 1px solid var(--neutral900);

          @media (max-width: $breakpointXl) {
            border-left: 1px solid var(--neutral900);
          }

          @media (max-width: $breakpointLg) {
            display: none;
          }

          p{
            text-wrap: nowrap;
            padding: 0;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            margin: 15px 0 0 0;
          }
          div{
              width: 45px;
              height: 45px;
              border-radius: 50%;
              border: 1px solid var(--neutral900);
              display: flex;
              align-items: center;
              justify-content: center;
              background: var(--neutral300);
              img{
                transition: all 0.2s ease-in-out;
              }
          }
      
      
        }

        .tabMobileButtons{
          display: none;

          @media (max-width: $breakpointSm){
            display: flex;
            justify-content: space-between;
            padding:20px 20px 0;
            align-items: center;
            border-bottom: 1px solid var(--neutral900);
            padding-bottom: 20px;

            h6{
              font-size: 1rem;
              font-weight: 400;
              margin: 0;
            }

            > div {
              display: flex; 
              align-items: center;

            }
  
            button {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              border: 1px solid var(--neutral900);
              align-items: center;
              justify-content: center;
              background-color: var(--white);
              cursor: pointer;
              transition: all 0.3s ease-in-out;
  
              &:hover{
                opacity: 0.75;
                transition: all 0.3s ease-in-out;
              }

              &:disabled{
                background-color: var(--neutral200);
                opacity: 0.5;

                  &:hover{
                    background-color: var(--neutral200);
                  }
                }
              
  
              &.prev{
                margin-right:20px;
                transform: rotate(90deg);
              }
  
              &.next{
                transform: rotate(-90deg);
              }
            }
          }


        }
    
        .tabsContainer {
          min-width: 620px;
          width: 100%;
          padding: 30px;
          height: 320px;
          box-sizing: border-box;

          @media (max-width: $breakpointSm){
            padding: 20px;
          }

          @media (max-width: $breakpointXl) {
            min-width: auto;
            height: auto;
          }
    
          .tabs {
            display: flex;
            margin-bottom: 24px;
            justify-content: space-between;
            position: relative;
    
            @media (max-width: $breakpointSm){
              display: none;
            }

            &::after {
              content: '';
              width: 100%;
              position: absolute;
              height: 1px;
              background-color: var(--neutral900);
              top: 50%;
              z-index: -1;
            }
          }

          .tab {
            cursor: pointer;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            border: 1px solid var(--neutral900);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--neutral900);
            background-color: var(--white);
            outline: 8px solid var(--white);
            transition: all 0.2s ease-in-out;
    
            &:hover {
              background-color: var(--accent-color-blue);
              transition: all 0.2s ease-in-out;
            }
          }
          
          .tab.active {
            background-color: var(--accent-color-blue);
          }
          
          .tabContent {

            @media (max-width: $breakpointSm){
              > div{
                display: flex;
                align-items: center;
                margin-bottom: 15px;
              }
            }

            span{
              display: none;
                @media (max-width: $breakpointSm){
                  width: 45px;
                  height: 45px;
                  border-radius: 50%;
                  border: 1px solid var(--neutral900);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: var(--neutral900);
                  background-color: var(--accent-color-blue);
                  margin-right: 20px;   
                }          
            }

            h6{
              font-size: 1.5rem;
              font-weight: 500;
              font-family: var(--font-family-content);
              margin: 0 0 16px 0;

              @media (max-width: $breakpointSm){
                width: calc(100% - 65px);
                margin: 0;
              }
            }
            p{
              margin: 0 0 25px 0;
              font-family: var(--font-family-content);
            }
          }
        }
      }
}

  
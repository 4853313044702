:root {
    --accent-color-blue: #196CF2;
    --accent-color-green: #A4E7AD;
    --accent-color-dark-blue: #20006B;
    --white: #FFFFFF;
    --black: #000000;
    --neutral50: #F8FAFC;
    --neutral100: #F1F5F9;
    --neutral200: #E2E8F0;
    --neutral300: #CBD5E1;
    --neutral400: #94A3B8;
    --neutral500: #64748B;
    --neutral600: #475569;
    --neutral700: #334155;
    --neutral800: #1E293B;
    --neutral900: #0F172A;
  }
$breakpointLg: 992px;
$breakpointXl: 1250px;

.headerWrapper {
    width: 100%;
    background-color: var(--neutral900);
    display: flex;
    justify-content: center;
}

.textWrap {
    padding: 0 20px 90px;
    margin-top: 120px;
    display: flex;
    align-items: center;
    border-left: 1px solid var(--neutral900);

    h2{
        margin-top:0;
    }

    @media (max-width: $breakpointXl){
        margin-top:70px;
        max-width: calc(100% - 140px);
        padding: 0 20px 40px;
    }
}

.accordionWrap {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 100px;
    max-width: var(--content-width);
    padding: var(--layout-padding);

    @media (max-width: $breakpointXl){
        margin-bottom: 70px;
    }
}

.whyUsContainer {
    box-sizing: border-box;
    width: 100%;
    max-width: var(--content-width);
    display: flex;
    justify-content: flex-end;
    padding: var(--layout-padding);

    .whyUs{
        border: 1px solid var(--neutral900);
        border-radius: 12px;
        margin-bottom: 110px;
        max-width: 800px;
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        position: relative;

        @media (max-width: $breakpointXl){
            max-width: 100%;
            margin-bottom: 70px;
        }

        .arrow{
            width: 156px;
            height: 216px;
            border-left: 1px solid var(--neutral900);
            border-bottom: 1px solid var(--neutral900);
            position: absolute;
            right: 100%;
            top:-100px;
            border-radius: 0 0 0 12px;

            &::after{
                content: url(../../assets/why-us-arrow.svg);
                position: absolute;
                bottom:-13px;
                right:0;

            }

            @media (max-width: $breakpointXl){
                height: 70px;
                width: 0;
                left: 30px;
                top:-71px;

                &::after {
                    transform: rotate(90deg);
                    bottom: -7.5px;
                    right: -2.6px;
                }
            }
        }

        h3 {
            font-size: 1.5rem;
            line-height: 2rem;
            font-family: var(--font-family-content);
            margin: 0;
            font-weight: 700;
        }

        p{
            margin: 5px 0 0 0 ;
            font-size: 1.5rem;
            line-height: 2rem;
            font-family: var(--font-family-content);
        }
        .title{
            h2 {
                margin: 0;
                font-size: var(--h2-font-size);
                line-height: var(--h2-line-height);
                border-bottom: 1px solid var(--neutral900);
                padding: 30px 40px;

                @media (max-width: $breakpointLg){
                    padding: 20px;
                }
            }
        }
        .content{
            padding: 30px 40px;

            @media (max-width: $breakpointLg){
                padding: 20px;

                p{
                    font-size: 1rem;
                    line-height: 140%;
                }
            }
        }
        .footer{
            border-radius: 0 0 12px 12px;
            background-color: var(--accent-color-dark-blue);
            padding: 30px 40px;
            color: var(--white);

            @media (max-width: $breakpointLg){
                padding: 20px;
            }

            p{
                color: var(--white);

                @media (max-width: $breakpointLg){
                   font-size: 1rem;
                   line-height: 140%;
                }
            }
        }

    }
}

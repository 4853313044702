$breakpointSm: 576px;
$breakpointL: 768px;

.privacyModal {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 100%;
    max-width: 470px;
    height: auto;
    display: flex;
    align-items: center;
    z-index: 10;

    @media (max-width: $breakpointL){
        max-width: 300px;
    }

    @media (max-width: $breakpointSm){
        max-width: calc(100vw - 20px);
        right: 10px;
    }

    .modalContent {
        border: 1px solid var(--neutral900);
        background-color: var(--white);
        padding: 20px;
        border-radius: 12px;
        width: 100%;
        font-family: var(--font-family-content);
        color: var(--neutral900);
        box-sizing: border-box;

        h2{
            margin:10px 0 0 0;
            font-size: 16px;
        }

        a {
            color: var(--neutral900);
            transition: all 0.3s ease-in-out;

            &:hover{
                opacity: 0.86;
                transition: all 0.3s ease-in-out;
            }
        }
    }

    .buttonWrap{
        display: flex;

        @media (max-width: $breakpointSm){
           font-size: 0.75rem;
        }
    }
        
    .closeButton {
        background-color: var(--accent-color-blue);
        color: var(--white);
        border: none;
        padding: 10px 20px;
        border-radius: 24px;
        cursor: pointer;

        transition: all 0.3s ease-in-out;

        &:hover{
            opacity: 0.86;
            transition: all 0.3s ease-in-out;
        }
    }

    .declineButton {
        color: var(--neutral400);
        border: 1px solid var(--neutral400);
        background-color: var(--white);
        padding: 10px 20px;
        border-radius: 24px;
        cursor: pointer;
        margin-left: 24px;
        transition: all 0.3s ease-in-out;

        &:hover{
            opacity: 0.86;
            transition: all 0.3s ease-in-out;
        }

        @media (max-width: $breakpointSm){
            margin-left: 12px;
         }
    }

    

    .closeButtonX{
        position: absolute;
        top:20px;
        right: 20px;
        background: none;
        border: none;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover{
            opacity: 0.86;
            transition: all 0.3s ease-in-out;
        }
    }
}

.privacyLayout {
    width: 100%;
    max-width: 740px;
    margin: 0 auto;
    padding: 120px 20px;
    box-sizing: border-box;
    color: var(--neutral900);

    @media (max-width: $breakpointL){
        padding: 100px 20px;
    }

    h1{
        margin-bottom: 32px;
        font-size: 40px;

        @media (max-width: $breakpointL){
            font-size: 24px;
        }
    }

    h2{
        font-size: 20px;
        line-height: 140%;
        margin: 25px 0 20px;
    }

    p, li {
        font-family: var(--font-family-content);
        font-size: 14px;
        line-height: 140%;
    }

    ul{
        margin: 0;
        padding: 0 0 0 20px;
        font-family: var(--font-family-content);
    }

    a {
        color: var(--neutral900);
        transition: all 0.3s ease-in-out;
        font-size: 14px;

        &:hover{
            opacity: 0.86;
            transition: all 0.3s ease-in-out;
        }
    }
}
  

  
$breakpointL: 768px;
$breakpointLg: 992px;
$breakpointXl: 1250px;

.overlayTop {
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background: linear-gradient(180deg, #110F2A 0%, rgba(15, 23, 42, 0) 100%);
}

.overlayGlobal {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom:0;
    left:0;
    background: linear-gradient(to bottom, rgba(17, 15, 42, 0), rgba(15, 23, 42, 0.5));
}

.loadingWrapper{
    min-height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.shareButton {
    background-color: #1877f2;  
    color: white;             
    padding: 10px 15px;        
    border: none;              
    border-radius: 5px;       
    font-size: 16px;           
    cursor: pointer;          
    display: flex;             
    align-items: center;      
    justify-content: center;   
    gap: 10px;    
    transition: all 0.3s ease-in-out;             
  }
  
  .shareButton:hover {
    background-color: #165db5; 
    transition: all 0.3s ease-in-out;             
  }
  
  .shareButton svg {
    fill: white;  
    height: 20px; 
  }

.blog {
    width: 100%;
    min-height: 100vh;

    @media (max-width: $breakpointL){
        min-height: 50vh;
    }

    .blogLayout {
        width: 100%;
        max-width: var(--content-width);
        margin:0  auto;
        padding: var(--layout-padding);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        position: relative;
        align-items: flex-start;
    }
    

    .latestArticle {
        height: 100vh;
        max-height: 800px;
        width: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        display: flex;
        position: relative;


        @media (max-width: $breakpointL){
            height: 75vh;
        }

        .featuredArticle{
            height: inherit;
            display: flex;
            justify-content: center;

            a{
                text-decoration: none;
            }
           
            h1{
                color: var(--white);
                font-size: var(--h1-font-size);
                line-height: var(--h1-line-height);
                max-width: 490px;
                margin: 0 0 5px 0;
            }

            p{
                color: var(--white);
                text-transform: uppercase;
                line-height: 140%;
                font-family: var(--font-family-content);
            }
        }
    }

    .filters{
        border: 1px solid var(--neutral900);
        border-radius: 12px;
        margin-bottom: 90px;
        margin-top: -45px;
        padding: 5px 15px 30px;
        background: rgba(255,255,255,0.2);

        h2{
            color: var(--white);
            font-weight: 500;
            font-size: 24px;
            line-height: 140%;
            margin:0 0 20px 0;
        }

        .buttonsWrap {
            display: flex;
            gap: 20px;

            @media (max-width: $breakpointLg){
                flex-wrap: wrap;
             }

            button {
                width: auto;
                height: 48px;
                border: 1px solid var(--neutral900);
                border-radius: 24px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px 30px;
                background-color: var(--white);
                color: var(--neutral900);
                transition: all 0.3s ease-in-out;
                font-weight: 500;
                cursor: pointer;

                &:hover {
                    background-color: var(--accent-color-blue);
                    transition: all 0.3s ease-in-out;
                    color: var(--white)
                }

                &:nth-child(1){
                    background-color: var(--accent-color-blue);
                    color: var(--white);

                    &:hover {
                       opacity: 0.8;
                    }
                }
            }
        }
    }

    .articlesWrapper{
        width: 100%;
        display: flex;
        gap: 20px;
        flex: 1;
        margin-bottom: 80px;

        @media (max-width: $breakpointLg){
           flex-wrap: wrap;
        }

        .blogPostCard {
            width: 50%;
            border: 1px solid var(--neutral900);
            border-radius: 12px;
            overflow: hidden;

            @media (max-width: $breakpointLg){
                width: 100%;
            }

            .imgWrapper{
                height: 100vh;
                max-height: 320px;
                width: 100%;
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center;
                position: relative;

                .overlay{
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top:0;
                    left:0;
                    background: linear-gradient(180deg, #110F2A 0%, rgba(15, 23, 42, 0) 100%);
                }

                img{
                    width: 100%;
                    max-width: 100%;
                    height: auto;
                }
            }

            a{
                text-decoration: none;
                transition: all 0.3s ease-in-out;

                &:hover {
                    opacity: 0.86;
                    transition: all 0.3s ease-in-out;
                }
            }

            h2 {
                height: 94px;
                font-size: 24px;
                line-height: 140%;
                font-family: var(--font-family-content);
                border-bottom: 1px solid var(--neutral900);
                padding: 15px 35px;
                box-sizing: border-box;
                margin:0;

                @media (max-width: $breakpointLg){
                    height: auto;
                }
            }

            .postFooter {
                color: var(--neutral900);
                font-family: var(--font-family-content);
                display: flex;
                justify-content: space-between;
                padding: 0 35px;
            }
        }
    }

    .pagination{
        margin-bottom: 94px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        gap: 8px;

        button {
            display: flex;
            border-radius: 3px;
            border: 1px solid var(--neutral900);
            transition: all 0.3s ease-in-out;
            opacity: 1;
            background-color: var(--white);
            font-family: var(--font-family-content);
            cursor: pointer;

            &.active {
                background-color: var(--neutral900);
                color: var(--white);
            }

            &:disabled{
                opacity: 0;
            }

            &:hover {
                background-color: var(--neutral900);
                color: var(--white);
            }
        }
    }
}

.singleArticleWrapper{
    margin: 120px 0;
    width: 100%;
    max-width: 840px;
    padding: 0 20px;
    box-sizing: border-box;

    .article {
        width: 100%;
        position: relative;
        border-radius: 12px;
        overflow: hidden;
        margin-bottom: 80px;

        img{
            width: 100%;
            max-width: 100%;
        }

        .content {
            position: absolute;
            left:30px; 
            bottom: 30px;
            z-index: 1;
            width: calc(100% - 60px);

            h1{
                color: var(--white);
                font-size: var(--h1-font-size);
                line-height: var(--h1-line-height);
                max-width: 490px;
                margin: 0 0 5px 0;
                @media (max-width: $breakpointL){
                    font-size: 1.5rem;
                }
            }
    
            p{
                color: var(--white);
                text-transform: uppercase;
                line-height: 140%;
                font-family: var(--font-family-content);
            }
        }
    }

    .articleContent {
        padding: 0 50px;

        @media (max-width: $breakpointL){
            padding: 0 20px;
        }

        h1{
            margin-bottom: 32px;
            font-size: 40px;
    
            @media (max-width: $breakpointL){
                font-size: 24px;
            }
        }

        h1, h2, h3, h4, h5 ,h6 {
            font-family: var(--font-family-content) !important;
            line-height: 160% !important;
        }
    
        p, li, span, b, strong {
            font-family: var(--font-family-content) !important;
            font-size: 18px !important;
            line-height: 160%;
        }

        figcaption {
            font-family: var(--font-family-content);
            font-size: 18px !important;
            line-height: 160%;
        }
    
        ul{
            margin: 0;
            padding: 0 0 0 20px;
            font-family: var(--font-family-content) !important;
        }
    
        a {
            color: var(--neutral900);
            transition: all 0.3s ease-in-out;
            font-size: 18px;
    
            &:hover{
                opacity: 0.86;
                transition: all 0.3s ease-in-out;
            }
        }

        img{
            display: flex !important;
            width: 100% !important;
            max-width: 100% !important;
            height: auto !important;
        }
    }
}

.loadingWrapper{
    display: flex;
    min-height: 70vh;
    align-items: center;
    justify-content: center;
}

@import url(../../globalStyles/typography.css);
$breakpointSm: 576px;
$breakpointL: 768px;
$breakpointLg: 992px;
$breakpointXl: 1250px;

.Services {
    width: 100%;

    .bannerText{
        width: 100%;
        height: 100vh;
        position: absolute;
        margin:0  auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        background-color: var(--neutral900);
        overflow: hidden;

        @media (max-width: $breakpointL){
            position: relative;
        }

        @keyframes rotate {
            from {
              rotate: 0deg;
            }
            
            50% {
              scale: 1 1.5;
            }
            
            to {
              rotate: 360deg;
            }
          }
          
        .blob {
            height: 44vmax;
            aspect-ratio: 1;
            position: absolute;
            left: 50%;
            top: 50%;
            translate: -50% -50%;
            border-radius: 50%;
            background: linear-gradient(to right, #20006B, #196CF2);
            animation: rotate 20s infinite;
            opacity: 0.8;

            @media (max-width: $breakpointL){
                height: 30vmax;
            }
        }
          
        .blur {
            height: 100%;
            width: 100%;
            position: absolute;
            z-index: 2;
            backdrop-filter: blur(12vmax);
        }

        .overlay {
            position: absolute;
            bottom: -10px;
            left: 0;
            width: 100%;
            height: 278px;
            background: linear-gradient(to bottom, rgba(15, 23, 42, 0) 0%, rgba(15, 23, 42, 0.9) 60%, rgba(15, 23, 42, 1) 100%);
            z-index: 2;

            @media (max-width: $breakpointL){
                height: 178px;
            }
        }
          
        .bannerContent {
            width: 100%;
            max-width: var(--content-width);
            margin: 0 auto;
            position: relative;
            z-index: 10;
            padding: var(--layout-padding);
            box-sizing: border-box;

                top: 50%;
                transform: translateY(-50%);


            h1{
                font-size: 56px;
                max-width: 726px;
                width: 100%;
                line-height: 140%;
                color: var(--white);
                margin: 0 0 20px 0;

                @media (max-width: $breakpointL){
                    font-size: 32px;
                }
            }

            .content {
                overflow:hidden;
                font-size: 36px;
                line-height: 140%;
                margin-bottom: 48px;

                @media (max-width: $breakpointL){
                    display: flex;
                    flex-wrap: wrap;
                    font-size: 20px;
                }

                .mobileText{
                    display: none;

                    @media (max-width: $breakpointL){
                       display: flex;
                       width: 100%;
                    }
                }
                
                &__container {
                  overflow: hidden;
                  height: 42px;
                  display: flex;
                  align-items: flex-start;

                @media (max-width: $breakpointL){
                    flex-wrap: wrap;
                    height: 40px;
                }
              
                  &__text {
                    display: inline;
                    float: left;
                    margin: 0;
                    color: var(--white);
                    line-height: 36px;

                    @media (max-width: $breakpointL){
                        display: none;
                    }
                  }
              
                  &__list {
                    margin-top: 0;
                    padding: 0 0 0 10px;
                    text-align: left;
                    list-style: none;
                    animation: scrollBack 18s infinite;

                    @media (max-width: $breakpointL){
                        padding: 0;
                    }
              
                    &__item {
                      line-height:40px;
                      margin:0;
                      color: var(--accent-color-blue);
                      text-transform: uppercase;
                      font-weight: 500;
                    }
                  }
                }

                @keyframes opacity {
                    0%, 100% {opacity:0;}
                    50% {opacity:1;}
                  }
                  
                  @keyframes scrollBack {
                    0%, 100% { transform: translateY(0); }
                    8.33% { transform: translateY(-16.66%); }
                    16.66% { transform: translateY(-33.32%); }
                    25% { transform: translateY(-50%); }
                    33.33% { transform: translateY(-66.66%); }
                    41.66% { transform: translateY(-83.32%); }
                    58.33% { transform: translateY(-66.66%); }
                    66.66% { transform: translateY(-50%); }
                    75% { transform: translateY(-33.32%); }
                    83.33% { transform: translateY(-16.66%); }
                    91.66% { transform: translateY(0); }
                  }
              }
                            
            a{
                font-size: 16px;
                text-decoration: none;
                width: 154px;
                height: 54px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 42px;
                color: var(--white);
                background-color: var(--accent-color-blue);
                transition: all 0.2s ease-in-out;
    
                &:hover{
                    opacity: 0.86;
                    transition: all 0.2s ease-in-out;
                }
            }

        }
    }


    .quoteAreaContainerWrap{
        width: 100%;
        display: flex;
        justify-content: center;
        background-color: var(--neutral900);

        @media (min-width: $breakpointL){
            margin-top:100vh;
        }
        
        .quoteAreaContainer{
            max-width: var(--content-width);
            width: 100%;
            padding: 70px 0;
            background-position: center right;
            background-repeat: no-repeat;
            display: flex;
            justify-content: center;

            @media(max-width: $breakpointLg){
                background-position: center calc(100% - 50px);
                padding: 70px 0 240px;
            }

            @media(max-width: $breakpointSm){
                background-position: center calc(100% - 56px);
                background-size: 40px;
                padding: 0 0 96px;
            }
            .quoteArea {
                width: var(--content-width);
                padding: var(--layout-padding);
                display: flex;
                align-items: center;

                @media(max-width: $breakpointLg){
                    flex-direction: column;
                    text-align: center;
                }

                img{
                    margin-left: 20px;

                    @media(max-width: $breakpointSm){
                        margin-left: 0;
                        max-width: 40px;
                        height: auto;
                    }
                }

                h2{
                    margin-left: 20px;
                    font-size: var(--h2-font-size);
                    line-height: var(--h2-line-height);
                    color: var(--white);
                    max-width: 850px;

                    @media(max-width: $breakpointXl){
                        max-width: 650px;
                        margin-left: 0;
                    }

                    @media(max-width: $breakpointSm){
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .aboutAreaContainer{
        background-color: var(--neutral900);
        display: flex;
        justify-content: center;

        .aboutArea {
            width: var(--content-width);
            padding: 0 20px 130px 20px;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            gap: 20px;

            @media (max-width: $breakpointLg){
                padding: 0 20px 70px 20px;
            }
    
            img{
                max-width: 100%;
                height: auto;
            }
    
            .grid {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;

    
                h6 {
                    font-size: 1.5rem;
                    line-height: 2.125rem;
                    color: var(--neutral50);
                    margin:0;
                    padding: 20px 20px 0;
                }
    
                p {
                    font-size: 1rem;
                    line-height: 1.375rem;
                    color: var(--neutral50);
                    padding: 10px 20px 20px;
                    font-family: var(--font-family-content);
                    margin:0;
                }

                .item2by3 {
                    flex: 2;

                    &.aboutImageMobile {
                        display: none;
                    }
    
                    @media (max-width: $breakpointSm) {
                        &.aboutImage {
                            display: none;
                        }
    
                        &.aboutImageMobile {
                            display: flex;
                            width: 100%;
    
                            img{
                                width: 100%;
                                height: auto;
                            }
                        }
                    }
                }
                  
                .item1by3 {
                    position: relative;

                    @media (min-width: $breakpointSm){
                        flex: 1;
                    }


                    &.folderItemNoAnimation{
                        @media (max-width: 840px){
                            display: none;
                        }
                    }
                    
                    &.contentBorder{
                        border: 1px solid var(--neutral50);
                        border-radius: 12px;
                    }
                    @media (max-width: $breakpointXl) {
                        &.folderItem {
                            display: none;
                        }
                    }
                    &.folderItemMobile{
                        display: none;
                        @media (max-width: 400px) {
                            display: flex;
                        }
                    }
                }
              }
        }
    }
    .tabsAreaContainer{
        display: flex;
        justify-content: center;
        margin: 160px 0;
        padding: var(--layout-padding);

        @media (max-width: $breakpointLg) {
            margin: 70px 0;
        }

        h2{
            font-size: var(--h2-font-size);
            line-height: var(--h2-line-height);
            color: var(--neutral900);
            margin: 40px 20px;

            @media (max-width: $breakpointSm) {
                margin: 20px;
            }
        }

        .contentArea{
            width: 100%;
            max-width: var(--content-width);
            border: 1px solid var(--neutral900);
            border-radius: 12px;
        }

        .tabsArea {
            border-top: 1px solid var(--neutral900);
            border-bottom: 1px solid var(--neutral900);
            display: flex;

            @media (max-width: $breakpointL) {
                flex-direction: column;
            }

            .tabs{
                display: flex;
                flex-direction: row;
                gap: 20px;
                padding: 20px;

                @media (min-width: $breakpointL) {
                    border-right: 1px solid var(--neutral900);
                }

                .tab{
                    width: 72px;
                    height: 72px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px solid var(--neutral900);
                    border-radius: 12px;
                    font-size: 1.5rem;
                    transition: all 0.2s ease-in-out;
                    cursor: pointer;

                    &.active, &:hover{
                        background-color: var(--accent-color-blue);
                        color: var(--white);
                        transition: all 0.2s ease-in-out;
                    }

                    @media (max-width: $breakpointL) {
                        width: 58px;
                        height: 58px;
                    }
                }
            }

            .tabContent{
                flex:2;
                padding: 15px;
                min-height: 135px;
                box-sizing: border-box;
                
                @media (max-width: $breakpointL) {
                    border-top: 1px solid var(--neutral900);
                   
                }
                h6 {
                    font-family: var(--font-family-content);
                    font-size: 1.5rem;
                    line-height: 2.125rem;
                    color: var(--neutral900);
                    margin:0;
                    font-weight: 700;
                }

                p {
                    font-family: var(--font-family-content);
                    margin:0;
                }
            }

        }

        .footer {
            background-color: var(--accent-color-dark-blue);
            border-radius: 0 0 12px 12px;
            justify-content: flex-end;
            display: flex;

            p{
                margin: 0 20px 12px ;
                font-size: 1.5rem;
                line-height: 2rem;
                color: #A4E7AD;
                font-weight: 700;
                font-family: var(--font-family-content);
            }

            @media (max-width: $breakpointSm){
                align-items: center;
                padding: 10px;
                p{
                    margin: 0;
                }
            }
        }
    }
    .servicesAreaContainer{
        background-color: var(--neutral900);
        display: flex;
        justify-content: center;
        min-height: 800px;
        position: relative;
        background-size: cover;

        @media(max-width: $breakpointLg){
            min-height: auto;
            padding: 70px 0;
        }

        .overlay {
            position: absolute;
            top:0;
            left: 0;
            background-color: rgba(0,0,0,0.6);
            width: 100%;
            height: 100%;
        }

        .servicesArea{
            width: 1340px;
            position: relative;
            display: flex;
            align-items: center;
            padding: var(--layout-padding);
            box-sizing: border-box;
        }

        .imgWrap{
            position: relative;

            @media(max-width: $breakpointLg){
                display: none;
            }
            
            > div{ 
                width: 60px;
                height: 60px;
                border-radius: 50%;
                background-position: center;
                background-size: cover;
                transition: all 0.2s ease-in-out;
                position: absolute;
                cursor: pointer;

                &:hover {
                    transition: all 0.2s ease-in-out;
                }
            }
        }

        .tabTitle1 {
            top: 60px;
            left: 114px;
            background-image: url(../../assets/landing_page/services1.svg);

            &:hover {
                background-image: url(../../assets/landing_page/services1-hover.svg);
            }
        }

        .tabTitle2 {
            top: 59px;
            left: 324px;
            background-image: url(../../assets/landing_page/services2.svg);

            &:hover {
                background-image: url(../../assets/landing_page/services2-hover.svg);
            }
        }

        .tabTitle3 {
            top: 204px;
            left: 465px;
            background-image: url(../../assets/landing_page/services3.svg);

            &:hover {
                background-image: url(../../assets/landing_page/DigitalMarketing.svg);
            }
        }

        .tabTitle4 {
            top: 404px;
            left: 465px;
            background-image: url(../../assets/landing_page/services4.svg);

            &:hover {
                background-image: url(../../assets/landing_page/GraphicdDesign.svg);
            }
        }

        .tabTitle5 {
            top: 546px;
            left: 322px;
            background-image: url(../../assets/landing_page/services5.svg);

            &:hover {
                background-image: url(../../assets/landing_page/ItConsulting.svg);
            }
        }

        .tabTitle6 {
            top: 544px;
            left: 114px;
            background-image: url(../../assets/landing_page/services6.svg);

            &:hover {
                background-image: url(../../assets/landing_page/services6-hover.svg);
            }
        }

        .tabButtons{
            display: none;

            @media (max-width: $breakpointLg){
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 32px;
            }

            h2{
                font-weight: 400;
                font-family: var(--font-family-content);
                color: var(--accent-color-green);
                text-transform: uppercase;
                margin: 0;
            }

            > div{
                display: flex;
                .tabsButtons{
                    width: 32px;
                    height: 32px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    border:none;
                    background-color: var(--white);
                    cursor: pointer;
                    transition: all 0.3s ease-in-out;
    
                    &:last-child{
                        margin-left: 24px;
                    }

                    &:hover{
                        background-color: var(--accent-color-blue);
                        transition: all 0.3s ease-in-out;
                    }

                    &:disabled{
                        background-color: var(--white);
                        opacity: 0.7;

                        &:hover{
                            background-color: var(--white);
                            opacity: 0.75;
                        }
                    }
                }
            }

        }
        
        .ourServicesTitle{
            color: var(--white);
            margin-left: 115px;
            font-size: 40px;

            @media(max-width: $breakpointXl){
                margin-left: 40px;
            }

            @media(max-width: $breakpointLg){
               display: none;
            }
        }

        .tabContent {
            padding: 25px 30px;
            background-color: #fff;
            margin-left: 115px;
            border-radius: 12px;

            @media(max-width: $breakpointXl){
                margin-left: 40px;
            }

            @media(max-width: $breakpointLg){
                margin-left: 0;
            }

            .firstRow {
                img {
                    display: none;
                }
            }


            h6{
                font-size: 1.5rem;
                line-height: 2.125rem;
                color: var(--neutral900);
                margin:0;

                @media(max-width: $breakpointLg){
                    line-height: 140%;
                }
            }

            p {
                color: var(--neutral900);
                font-family: var(--font-family-content);
                font-size: 1.25rem;
            }

            @media(max-width: $breakpointLg){
                .firstRow {
                    display: flex;
                    align-items: center;

                    img {
                        display: block;
                        margin-right: 20px;
                        border-radius: 50%;
                    }
                }
            }
            
            .buttonWrap{
                display: flex;
                a {
                    padding: 20px 28px;
                    display: flex;
                    border:1px solid var(--neutral900);
                    background-color: var(--accent-color-blue);
                    align-items: center;
                    justify-content: center;
                    border-radius: 24px;
                    max-width: 90px;
                    transition: all 0.2s ease-in-out;
    
                    &:hover {
                        opacity: 0.86;
                        transition: all 0.2s ease-in-out;
                    }
                }
                justify-content: flex-end;
            }

        }
        
        .tabPane {
            display: none;
        }
        
        .tabPaneActive {
            display: block;
        }
  
        
    }
    .textArea{
        display: flex;
        justify-content: center;
        padding: 160px 20px;

        @media(max-width: $breakpointXl){
            padding: 70px 20px;
            text-align: center;
        }

        h2{
            margin:0;
            font-size: var(--h2-font-size);
            line-height: var(--h2-line-height);
            color: var(--neutral900);
            max-width: var(--text-width);

        }
        .blueText {
            color: var(--accent-color-blue)
        }

    }
}
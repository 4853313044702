$breakpointXs: 575px;
$breakpointSm: 576px;
$breakpointMd: 768px;
$breakpointLg: 992px;
$breakpointXlmin: 1251px;
$breakpointHeader: 1150px;
$breakpointXl: 1250px;

.Header {
  position: absolute;
  left:50%;
  transform: translateX(-50%);
  z-index: 20;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: var(--content-width);
  padding: 20px;
  box-sizing: border-box;

   img {
    width: 183px;
    height: auto;

    @media(max-width: $breakpointXl){
      width: 147px;
    }

    @media (max-width: $breakpointSm){
      position: relative;
      z-index: 12;
    }
  }

  nav {
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;
    box-sizing: border-box;
    position: relative;
    
    @media (max-width: $breakpointXl) {
      display: flex;
      position: fixed;
      top: 0;
      bottom: 0;
      width: 360px;
      height: 100vh;
      background-color: var(--neutral900);
      justify-content: center;
      z-index: 0;
      padding-top: 90px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
      transform: translateX(calc(100vw - 380px));
      transition: transform 0.3s ease-in-out;
      will-change: transform;
      align-items: flex-start;

      &.navHidden {
        transform: translateX(100vw);
      }
    }

    @media (max-width: $breakpointSm) {
      width: 100%;
      transform: translateX(-20px);
      &.navHidden {
        transform: translateX(100%); 
      }
    }

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;
      align-items: center;
      

      @media (max-width: $breakpointXl) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        width: 100%;
        max-width: 360px;
      }


      @media (max-width: $breakpointSm){
        max-width: 100%;
      }

      li {
        margin: 0 1rem;
        padding: 1rem;
        position: relative;
        font-size: 1rem;
        border-radius: 8px;
        cursor: pointer;
        @media (max-width: $breakpointXl) {
          width: 100%;
          box-sizing: border-box;
          margin:0;
          padding: 1rem 2rem;
        }

        a {
          text-decoration: none;
          color: var(--white);
          transition: color 0.2s ease-in-out;
          font-weight: 500;
          display: flex;
          width: 100%;
          align-items: center;
          box-sizing: border-box;

          &:hover, &:active {
            color: var(--accent-color-blue);
            transition: color 0.2s ease-in-out;
          }
        }

        &:last-child {
          border: 1px solid var(--accent-color-blue);
          padding: 1rem 2rem;
          border-radius: 2rem;
          transition: background-color 0.2s ease-in-out;

          @media (min-width: $breakpointXlmin) {
            margin-right: 0;
          }

          @media (max-width: $breakpointXl) {
            position: fixed;
            bottom: 80px;
            left: 50%;
            transform: translateX(-50%);
            width: auto;
            background-color: var(--accent-color-blue);
            transition: all 0.2s ease-in-out
          }

          &:hover {
            opacity: 0.86;
            transition: all 0.2s ease-in-out;
            a{
              color: var(--white);
            }
          }
        }

        &.withSubmenu {
          position: relative;
          padding-right: 1.25rem;

          svg {
            padding-left: 0.75rem;
            transition: all 0.2s ease-in-out;

            @media (max-width: $breakpointXl){
              position: absolute;
              right: 1.5rem;
              top:22px;
            }

            path {
              transition: all 0.2s ease-in-out
            }
          }

          :hover, &.activeDropdown {
            > a{
              color: var(--accent-color-blue);
            }
            svg {

              path {
                stroke: var(--accent-color-blue);
                transition: all 0.2s ease-in-out
              }
            }
          }

          &.activeDropdown {
            svg {
              transform: scaleY(-1);
              transition: all 0.2s ease-in-out;
            }
          }

          .submenu {
            @media (max-width: $breakpointXl){
              padding-top:1rem;
            }

            @media (min-width: $breakpointXlmin) {
              padding: 1rem;
              z-index: 1;
              background-color: var( --neutral900);
              border-radius: 0.75rem;
              position: absolute;
              top: calc(100% + 8px);
              left: 50%;
              transform: translateX(-50%);
              min-width: 312px;
              display: block;
              border: 1px solid var(--white);
            }
           
            li {
              margin: 0 0 5px 0!important;
              padding: 0 !important;

              a{
                display: block;
                padding: 1rem;
                border-radius: 12px;
                border: 1px solid transparent;
                transition: border 0.2s ease-in-out;
              }

              &:last-child {
                border: none;
                background: transparent;
                padding: 1rem 0;
                width: 100%;
      
                @media (max-width: $breakpointXl) {
                  position: static;
                  bottom:0;
                  left: 0%;
                  transform: none;
                }
              }

              a:hover, .activeLink {
                color: var(--white);
                border: 1px solid  var(--accent-color-blue);
                transition: border 0.2s ease-in-out;
              }
            }
          }          
        }
      }
    }

    > ul {
      @media (max-width: $breakpointXl) {
        height: calc(100% - 150px);
        overflow: scroll;
      }
    }
  }
  @media(min-width: $breakpointXlmin){
    &.lightTheme{
      nav {
        li{
          a{
            color: var(--neutral900);
    
            &:hover, &:active {
              color: var(--accent-color-blue);
              transition: color 0.2s ease-in-out;
            }
          }
  
          &.withSubmenu {  
            svg {  
              path {
                stroke: var(--neutral900);
              }
            }
          }
        }
  
  
        .submenu {
          a{
            color: var(--white)
          }
        }
      }
    }
  }

  .menuIcon, .closeIcon {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    flex-wrap: wrap;
    transition: background-color 0.3s;
    display: none;
    z-index: 11;

    @media (max-width: $breakpointXl){
      display: flex;
    }
  
    .line {
      width: 21px;
      height: 2px;
      background-color: var(--white);
      transition: transform 0.3s, opacity 0.3s, top 0.3s;
      position: absolute;
  
      &:nth-child(1) {
        top: 14px;
      }
  
      &:nth-child(2) {
        top: 20px;
      }
  
      &:nth-child(3) {
        top: 26px;
      }
    }
  }
  
  .menuIcon {
    border: 1px solid var(--accent-color-blue);
  }
  
  .closeIcon {
    border: 1px solid var(--accent-color-blue);
    background-color: var(--accent-color-blue);
    
    .line:nth-child(1) {
      transform: rotate(45deg);
      top: 20px; 
    }
  
    .line:nth-child(2) {
      top: 20px;
      transform: rotate(-45deg);
    }
  }

  &.lightTheme {
    .line {
      background-color: var(--neutral900);
    }
    .closeIcon {
      .line {
        background-color: var(--white);
      }
    }
  }
}

.headerWraper {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .headerProducts {
    box-sizing: border-box;
    padding: 200px 20px 130px;
    width: 100%;
    max-width: var(--content-width);

    @media (max-width: $breakpointXl){
      border: 1px solid var(--white);
      padding:0;
      width: calc(100% - 40px);
      margin:100px 20px 70px;
      border-radius: 12px;
    }

    .mobileImg{
      display: none;

      @media (max-width: $breakpointHeader){
        display: flex;
        border-bottom: 1px solid var(--white);

        img {
          width: 100%;
        }
      }
    }
  
    .titleRow{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      border: 1px solid var(--white);
      border-radius: 12px 12px 0 0 ;
      padding: 20px 45px 10px;

      @media (max-width: $breakpointHeader){
        border: none;
        padding: 40px 15px 0px;
      }
  
      h1 {
        margin:-10px 20px 8px 0;
        font-size: var(--h1-font-size);
        line-height: var(--h1-line-height);
        color: var(--white);
        white-space: nowrap;

        @media (max-width: $breakpointHeader){
          white-space: normal;
          font-size: 2rem;
          line-height: 140%;
        }

        @media (max-width: $breakpointSm){
          font-size: 1.5rem;
          line-height: 140%;
        }


      }

      h2{
        color: var(--white);
      }

    }
  
    .contenRow {
      margin-top: -1px;
      border: 1px solid var(--white);
      border-radius: 0 0 12px 12px;
      display: flex;

      @media (max-width: $breakpointHeader){
        border: none
      }
      
      .content{
        box-sizing: border-box;
        width: 50%;
        font-size: 1.5rem;
        line-height: 2rem;
        color: var(--white);
        font-family: var(--font-family-content);
        padding: 30px 45px;
        border-right: 1px solid var(--white);

        @media (max-width: $breakpointHeader){
          width: 100%;
          border: none;
          padding: 5px 15px 15px 15px;
        }

        p{
          color: var(--white);
          @media (max-width: $breakpointHeader){
            margin-top: 0;
            font-size: 1.2rem;
          }
          @media (max-width: $breakpointXs){
            font-size: 1rem;
          }
        }
      }
      .img{
        width: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        @media (max-width: $breakpointHeader){
          display: none;
        }
      }
    }
  }

  .headerSlider {
    width: 100%;
    border-top: 1px solid var(--white);
  }
}

.headerSlides .slick-slide {
  width: 800px !important;
}

.headerSlides {
  width: 100%;

  .slideWrap {
    display: flex !important;
    align-items: center;
    height: 100%;
    height: 120px;
    
    img {
      max-width: 65px;
      height: auto;
      margin: 0 1.5rem;
    }

    p{
      font-size: 1.75rem;
      color: var(--accent-color-blue);

      @media(max-width: 1500px){
        font-size: 1.4rem;
      }

      @media(max-width: 1300px){
        font-size: 1.2rem;
      }

      @media(max-width: 1250px){
        font-size: 1.75rem;
      }

      @media(max-width: 800px){
        font-size: 1.2rem;
      }
    }
  }

}


@import url(./colors.css);

:root{
   --h1-font-size: 3rem;
   --h1-line-height: 4.25rem;
   --h2-font-size: 2.5rem;
   --h2-line-height: 3.5rem;
}

@media(max-width: 1250px){
   :root{
      --h1-font-size: 2.5rem;
      --h1-line-height:  140%;
      --h2-font-size: 2rem;
      --h2-line-height: 140%;
   }
}

@media(max-width: 576px){
   :root{
      --h1-font-size: 2rem;
      --h1-line-height: 140%;
      --h2-font-size: 1.5rem;
      --h2-line-height: 140%;
   }
}


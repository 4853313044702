$breakpointXl: 1250px;

.textWrap {
    width: 100%;
    max-width: 700px;

    @media(max-width: $breakpointXl){
        max-width: 100%
    }

    h2 {
        color: var(--neutral900);
        font-weight: 600;
        font-size: var(--h2-font-size);
        line-height: var(--h2-line-height);

        span{
            color: var(--accent-color-blue);
        }
    }
}


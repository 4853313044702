$breakpointSm: 576px;
$breakpointLg: 992px;
$breakpointXl: 1250px;

.footerContainer {
    width: 100%;
    background-color: var(--neutral900);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 70px 20px 20px;
    box-sizing: border-box;
    margin-top: -1px;
}

.Footer {
    padding: 60px 20px 0;
    width: 100%;
    max-width: var(--content-width);
    font-family: var(--font-family-content);

    .logo{
        position: relative;
        max-width: 366px;
        z-index: 1;

        @media (max-width: $breakpointLg){
            max-width: 150px;
        }
    }
    
    ul {
        list-style: none;
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
    }

    a {
        text-decoration: none;
        color: var(--white);
        transition: color 0.2s;

        &:hover {
            color: var(--accent-color-blue);
        }
    }

    .footerContacts {
        display: flex;
        justify-content: flex-end;
        padding: 0 0 20px 0;
        margin-top: - 20px;
        @media (max-width: $breakpointLg){
            justify-content: space-between;
        }
        @media (max-width: $breakpointLg){
            > div {
                > img {
                    max-width: 100%;
                    width: 100%;
                    margin-left: 10vw;
                    margin-top: 20px;
                }
            }
        }

        .line{
            width: 60vw;
            max-width: 600px;
            background-image: url(../../assets/line.svg);
            background-repeat: no-repeat;
            height: 45px;

            @media (max-width: $breakpointXl){
                width: 40vw;
                background-size: 400px;
            }
            @media (max-width: $breakpointLg){
                margin-top:15px;
                margin-left:20px;
                background-image: url(../../assets/footer-line.svg);
                width: 135px;
                height: 40px;
                background-size: auto;
            }
            @media (max-width: $breakpointSm){
                background-size: contain;
            }
        }

        li {
            margin-left: 1.5rem;
            position: relative;
            font-size: 1rem;
            display: flex;
            align-items: center;

            &:last-child {
                margin-right: 0;
            }
        }

        .contactInfo {
            display: flex;
            align-items: flex-end;
            padding-bottom: 4px;

            img{
                margin-right: 1rem;
            }

            @media (max-width: $breakpointLg){
                ul{
                    flex-direction: column;
                    margin-top:35px;

                    li{
                        margin-bottom: 1rem;
                    }
                }
            }
        }
    }


    .footerLink {
        display: flex;
        justify-content: space-between;
        padding: 20px 0;

        @media (max-width: $breakpointLg){
            justify-content: center;
            flex-wrap: wrap;


            .privacyPolicyLink{
                order: 2;
                width: 100%;
                text-align: center;
                margin-bottom: 1rem;
            }

            .copyrights{
                order: 3;
                width: 100%;
                text-align: center;
            }
        }



        .socialLinks{
            @media (max-width: $breakpointLg){
                order: 1;
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 1rem;
            }
            li{
                margin: 0 1rem;
    
                &:last-child {
                    margin-right: 0;
                }
            }
        } 

        .social {
            transition: opacity 0.2s ease-in-out;
            &:hover {
                opacity: 0.8;
                transition: opacity 0.2s ease-in-out;
            }

        }
    }
}
@import '../node_modules/normalize.css/normalize.css';
@import url(globalStyles/colors.css);
@import url(globalStyles/layout.css);

$breakpointL: 768px;

:root {
    --font-family: 'IBM Plex Sans', monospace;
    --font-family-content: 'IBM Plex Mono', monospace;
  }

body{
  font-family: var(--font-family);
  font-display: swap;

  @media (max-width: 568px){
    overflow-x: hidden;
  }
}

.app {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: var(--main-width);
  margin: 0 auto;
  position: relative;
  overflow-x: hidden;
}

p, h2 {
  color: var(--neutral900)
}

p{
  line-height: 140%;
}

* {
  -webkit-tap-highlight-color: transparent !important;
}

.modal {
  font-family: var(--font-family-content);

  button {
    position: absolute;
    top:10px;
    right:10px;
    margin:0;
    min-width: 40px;
    height: 40px;
    color: var(--neutral900)
  }

  .modalContent{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 60px 32px 40px;
    max-width: 800px;
    border-radius: 12px;

    @media (max-width: $breakpointL){
      box-sizing: border-box;
      width: 90vw;
      max-height: 80vh;
      overflow: auto;
    }
  }

  > p{
    margin-bottom: 30px;
  }

  ul{
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 10px;

    li{
      list-style: none;
      position: relative;
      padding-left: 25px;
      margin-bottom: 25px;
      line-height: 140%;

      &::after{
        content: url(./assets/li1.svg);
        position: absolute;
        left: 0;
        top: -1px;
      }


      &:nth-child(2){
        &::after{
          content: url(./assets/li2.svg);
        }
      }

      &:nth-child(3){
        &::after{
          content: url(./assets/li3.svg);
          top: 1px;
        }
      }

      &:nth-child(4){
        &::after{
          content: url(./assets/li5.svg);
          top: 2px;
        }
      }

      &:nth-child(5){
        &::after{
          content: url(./assets/li6.svg);
          top: 2px;
        }
      }

    }
  }
}

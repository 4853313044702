.logoSlider {
    width: 100%;
    margin: 0 auto;
  }
  
  .logo {
    text-align: center;
    padding: 20px;
  }
  
 
  
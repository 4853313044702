$breakpointL: 768px;
$breakpointLg: 992px;
$breakpointXl: 1250px;
$padding: 30px;

.loadingWrapper{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vacanciesWrapper {
    margin: 170px 0 90px;
    width: 100%;
    min-height: 100vh;
    max-width: var(--content-width);
    padding: 0 20px;
    box-sizing: border-box;

    @media (max-width: $breakpointL) {
        margin: 100px 0 50px;
    }

    .mainBlock{
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 592px;
        margin:0 auto;

        .imgWrapper{
            display: flex;
            width: 100%;
            height: auto;
            background-color: var(--neutral900);
            border-radius: 12px 12px 0 0;

            > img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }
        }
    }

    .mainBlockFooter{
        border: 1px solid var(--neutral900);
        border-radius: 0 0 12px 12px;
        min-height: 160px;
        margin-top: -2px;
        display: flex;
        margin-bottom: 150px;

        .leftBlock {
            border-right: 1px solid var(--neutral900);
            width: 100%;
            max-width: 182px;
            position: relative;

            &::after {
                content: "";
                display: flex;
                width: 236px;
                height: 1px;
                background-color: var(--neutral900);
                transform: rotate(-42deg);
                position: absolute;
                bottom: 80px;
                left: -25px;

                @media (max-width: 400px){
                    display: none;
                }
            }
        }

        .rightBlock{
            display: flex;
            align-items: flex-start;
            width: 100%;
            position: relative;

            &::before{
                content: '';
                height: 311px;
                width: 1px;
                background-color: var(--neutral900);
                position: absolute;
                top:0;
                left:-1px;
            }

            &::after{
                content: '';
                display: flex;
                width: 12px;
                height: 12px;
                border-left: 1px solid var(--neutral900);
                border-bottom: 1px solid var(--neutral900);
                transform: rotate(-45deg);
                position: absolute;
                left: -7px;
                bottom: -149px;
            }

            h1{
                border-bottom: 1px solid var(--neutral900);
                width: 100%;
                margin: 0;
                padding: 30px 20px;
                font-family: var(--font-family-content);

                @media (max-width: $breakpointL){
                    font-size: 1.5rem;
                }
            }
        }
    }

    .vacanciesHeader{
        display: flex;
        width: 100%;
        max-width: var(--content-width);
        margin:0  auto;
        border: 1px solid var(--neutral900);
        border-radius: 12px 12px 0 0;
        border-bottom: none;
        height: 23px;

        @media (max-width: $breakpointLg){
            display: none;
        }
    }

    .vacanciesPosts {
        display: flex;
        width: 100%;
        max-width: var(--content-width);
        margin:0  auto;
        padding: 0 20px;
        box-sizing: border-box;
        gap: 20px;
        flex-wrap: wrap;

        @media (max-width: $breakpointLg){
            padding:0
        }

        .cardLink{
            width: 33%;
            display: flex;
            border: 1px solid var(--neutral900);
            border-radius: 12px;
            overflow: hidden;
            flex-direction: column;
            text-decoration: none;
            color: var(--neutral900);

            @media (min-width: $breakpointLg){
                flex-basis: calc(33% - (20px * 2 / 3));
            }

            @media (max-width: $breakpointLg){
                width: 100%;
                margin-bottom: 20px;
            }
            

            > img {
                width: 100%;
                max-width: 100%;
                height: auto;
            }

            .cardImg{
                width: 100%;
                height: 267px;
                background-size: cover;
                background-position: center;
            }

            .cardBody{
                padding: 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            h5{
                font-family: var(--font-family-content);
                font-size: 24px;
                margin: 0;
                width: calc(100% - 55px);
                
            }

            span { 
                width: 40px;
                height: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                border: 1px solid var(--neutral900);
                background: var(--neutral300);
                transition: all 0.3s ease-in-out;

                img{
                    transform: rotate(-90deg);
                }

            }
        }
    }
}

.vacancySingleWrap{
    max-width: var(--content-width);
    margin:170px auto 90px;
    padding: 0 20px;
    box-sizing: border-box;

    @media (max-width: $breakpointL) {
        margin: 100px 0 50px;
    }
}

.vacancySingle{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--neutral900);
    overflow: hidden;
    border-radius: 12px;

    h1{
        margin-bottom: 32px;
        font-size: 40px;

        @media (max-width: $breakpointL){
            font-size: 24px;
        }
    }

    h1, h2, h3, h4, h5 ,h6 {
        font-family: var(--font-family-content) !important;
        line-height: 160% !important;
    }

    p, li, span, b, strong {
        font-family: var(--font-family-content) !important;
        font-size: 16px !important;
        line-height: 160%;
    }

    figcaption {
        font-family: var(--font-family-content);
        font-size: 16px !important;
        line-height: 160%;
    }

    ul{
        margin: 0;
        padding: 0 0 0 20px;
        font-family: var(--font-family-content) !important;

        li {
            list-style: none;
            position: relative;

            &::before {
                content: '';
                display: flex;
                position: absolute;
                width: 8px;
                height: 8px;
                left: -16px;
                top: 12px;
                background-color: var(--neutral900);
            }
        }
    }

    a {
        color: var(--neutral900);
        transition: all 0.3s ease-in-out;
        font-size: 16px;

        &:hover{
            opacity: 0.86;
            transition: all 0.3s ease-in-out;
        }
    }

    img{
        display: flex !important;
        width: 100% !important;
        max-width: 100% !important;
        height: auto !important;
    }

    .title {
        padding: 0 30px;
        border-bottom:1px solid var(--neutral900);
    }

    .pattern {
        display: flex;
        min-width: 286px;;
        width: 286px;
        height: inherit;
        border-left: 1px solid var(--neutral900);

        @media (max-width: $breakpointLg) {
            display: none;
        }
    }

    .patternStart {
        display: flex;
        min-width: 80px;;
        width: 80px;
        height: inherit;
        border-right: 1px solid var(--neutral900);

        @media (max-width: $breakpointLg) {
            display: none;
        }
    }

    .contenIntro{
        font-family: var(--font-family-content) !important;
        border-bottom:1px solid var(--neutral900);
        display: flex;
        line-height: 140%;

        .content {
            padding: $padding;
        }
    }

    .contentOnetitle{
        border-bottom:1px solid var(--neutral900);
        font-family: var(--font-family-content);
        display: flex;

        h2 {
            margin: 0;
            padding: $padding;
            width: 100%;
        }

        .pattern {
            background-color: var(--neutral300);
        }
    }

    .contentOne {
        display: flex;
        border-bottom:1px solid var(--neutral900);
        font-family: var(--font-family-content) !important;

        .patternStart{
            justify-content: center;
            align-items: center;

            div {
                width: 45px;
                height: calc(100% - 60px);
                background-color: var(--neutral300);
                border-radius: 50px;
                border: 1px solid var(--neutral900);
                position: relative;

                &::after{
                    content: "";
                    position: absolute;
                    height: 50%;
                    width: 1px;
                    background-color: var(--neutral900);
                    top:50%;
                    transform: translateY(-50%);
                    left: 50%;
                }

                &::before{
                    content: '';
                    display: flex;
                    width: 12px;
                    height: 12px;
                    border-left: 1px solid var(--neutral900);
                    border-bottom: 1px solid var(--neutral900);
                    transform: rotate(-45deg);
                    position: absolute;
                    left: calc(50% - 6px);
                    bottom: 25%;
                }
            }
        }

        .content {
            padding: $padding;
        }
    }

    .quote{
        padding: $padding;
        font-family: var(--font-family-content);
        font-weight: 500;
        border-bottom:1px solid var(--neutral900);

        p {
            margin: 0;
            line-height: 140%;
        }
    }

    .contenTwoTitle{
        border-bottom:1px solid var(--neutral900);
        font-family: var(--font-family-content) !important;
        display: flex;

        h2 {
            padding: $padding;
            margin: 0;
        }
    }

    .contentTwo{
        display: flex;
        border-bottom:1px solid var(--neutral900);
        font-family: var(--font-family-content) !important;
        
        .patternStart {
            padding: 30px 0;
            overflow: hidden;
            position: relative;

            p {
                position: absolute;
                writing-mode: vertical-lr;
                margin: 0;
                height: calc(100% - 60px);
                top:30px;
                left: 50%;
                transform: translateX(-50%);
                overflow: hidden;
            }
        }
    
        .content{
            padding: $padding;
        }
    }

    .contentTags{
        padding: $padding;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 20px;
        border-bottom:1px solid var(--neutral900);



        p{
            margin: 0;
            padding: 13px 24px;
            border-radius: 24px;
            line-height: 20px;
            border: 1px solid var(--neutral900);
        }
    }

    .footer {
        display: flex;
        @media (max-width: $breakpointLg) {
            flex-wrap: wrap;
            margin-bottom: 30px;
        }

        .firstBlock{
            width: 25%;
            border-right: 1px solid var(--neutral900);
            background-color: var(--neutral300);

            @media (max-width: $breakpointLg) {
                display: none;
            }
        }

        .secondBlock {
            padding: $padding;
            border-right: 1px solid var(--neutral900);
            width: 50%;

            @media (max-width: $breakpointLg) {
                border-right: none;
                width: 100%;
                text-align: center;
            }

            p {
                margin: 0;
                color: var(--accent-color-blue);
                font-size: 2rem !important;
                line-height: 140%;
                font-weight: 700;
            }
        }

        .thirdBlock {
            width: 25%;
            display: flex;
            align-items: center;
            justify-content: center;

            @media (max-width: $breakpointLg) {
                width: 100%;
            }

            button {
                background-color: var(--accent-color-blue);
                border: none;
                color: var(--white);
                border-radius: 24px;
                padding: 13px 50px;
                cursor: pointer;
                transition: all 0.3s ease-in-out;

                &:hover {
                    transition: all 0.3s ease-in-out;
                    opacity: 0.86;
                }
            }
        }
    }
}

.modal {
    .modalContent{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        max-width: calc(var(--content-width) - 140px);
        padding: 60px 32px 40px;
        width: 90vw;
        border-radius: 12px;

        @media (max-width: $breakpointL){
            box-sizing: border-box;
            width: 98vw;
            max-height: 80vh;
            overflow: auto;
            padding: 60px 20px 40px;
        }

        .errorText{
            display: flex;
            color: #d32f2f;
            margin: 0 0 20px !important;
            font-size: 0.75rem;
        }

        .closeModal{
            position: absolute;
            top:0;
            right: 20px;
            color: var(--neutral900);
            transition: all 0.2s ease-in-out;

            &:hover{
                transition: all 0.2s ease-in-out;
                opacity: 0.86;
            }
        }

        .formWrap {
            border: 1px solid var(--neutral900);
            background-color: var(--neutral200);
            border-radius: 12px;
            box-sizing: border-box;
    
            @media(max-width: $breakpointL){
                width: 100%;
                padding: 20px;
                border-radius: 0 0 12px 12px;
            }

            .inputWrap{
                padding: 20px 35px 0 ;

                @media (max-width: $breakpointL){
                    padding: 0;
                }
            }
    
            .textFieldRow{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                > div {
                    width: calc(50% - 12px);
                    margin-bottom: 20px;
    
                    @media(max-width: $breakpointL){
                        width: 100%;
                    }
                    
                    .textField{
                        margin-bottom: 40px;
                        position: relative;
                        
                    }
                }
            }

            .textArea{
                width: 100%;
                margin-bottom: 20px;
            }

            .agreeTerms{
                margin-bottom: 20px;

            }
    
            p{
                margin: 0;
                font-size: 0.75rem;
            }
    
            .footer{
                display: flex;
                justify-content: space-between;
                border-top: 1px solid var(--neutral900);

                @media (max-width: $breakpointLg){
                    flex-wrap: wrap;
                }

                > div {
                    border-right: 1px solid var(--neutral900);
                    padding: 10px;
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: column;

                    @media (max-width: $breakpointL){
                        padding: 10px 0;
                    }

                    &:first-child {
                        width: 304px;
                    }

                    &:last-child{
                        border: none;
                        width: 294px;
                    }

                    @media (max-width: $breakpointLg){
                        width: 100% !important;
                        border-right: none;

                    }                    
                }

                .fileInputContainer {
                    flex: 1;
                    label {
                        height: 100%;
                    
                        > span {
                            border-radius: 12px;
                            border-color:  var(--accent-color-blue);
                            color: var(--neutral900);
                            display: flex;
                            flex-wrap: wrap;
                            width: 100%;
                            height: 100%;
                            justify-content: flex-start;
                            text-transform: none;
                            font-weight: 700;
                        }

                        .fileSizeText{
                            display: flex;
                            width: 100%;
                            font-weight: 400;
                        }
                    }
                }

                .submitButton{
                    width: 100%;
                    height: 100%;
                    border-radius: 12px;
                    border: 1px solid var(--neutral900);
                    background-color: var(--accent-color-blue);
                }
            }
        }
    }
}
$breakpointSm: 576px;
$breakpointL: 768px;
$breakpointLg: 992px;

.accordion {
    width: 100%;
    border: 1px solid var(--neutral900);
    border-radius: 12px;
    padding: 30px 50px 60px 50px;
    box-sizing: border-box;

    @media (max-width: $breakpointLg){
      padding: 15px 20px 40px 20px;
    }
  
    .accordionItem {
  
      .accordionTitle {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--neutral900);
        position: relative;

        @media (max-width: $breakpointLg){
          padding: 10px 50px 10px 0;
          align-items: center;
          min-height: 70px;
          box-sizing: border-box;

        }

        span{
            font-size: 1.5rem;
            line-height: 2rem;
            min-width: 80px;
            @media (max-width: $breakpointLg){
              font-size: 0.75rem;
              min-width: 30px;
              line-height: 1.5rem;
            }
        }

        p{
            margin:0;
            font-size: 2rem;
            line-height: 2.75;
            font-weight: 600;
            font-family: var(--font-family-content);

            @media (max-width: $breakpointLg){
              font-size: 1rem;
              line-height: 140%;
            }
        }
        div {
          position: absolute;
          right: 10px;
          top:50%;
          transform: translateY(-50%);
          width: 45px;
          height: 45px;
          border-radius: 50%;
          border: 1px solid var(--neutral900);
          display: flex;
          align-items: center;
          justify-content: center;
          background: var(--neutral300);
          @media (max-width: $breakpointLg){
            width: 30px;
            height: 30px;
          }
          img{
            transition: all 0.2s ease-in-out;
          }
        }
  
        &.active {
          div{
            img{
              transform: scale(-1);
              transition: all 0.2s ease-in-out;
            }
          }

        }
      }
  
      .accordionContent {
        display: flex;
        max-height: 0; 
        transition: all 0.25s ease-in-out;
        overflow: hidden;

        @media (max-width: $breakpointLg){
          flex-wrap: wrap-reverse;

          p{
            margin: 0;
          }
        }

        img{
            margin-right: 1.5rem;
            width: 338px;
            height: 168px;

            @media (max-width: $breakpointLg){
              display: none;
            }

            @media (max-width: $breakpointSm){
              display: block;
              width: calc(100% - 40px);
              height: auto;
              margin: 20px;
            }
        }

        .contentWrap {
          display: flex;
          align-items: center;
          border: 1px solid var(--neutral900);
          border-radius: 0 11px 12px 0;
          padding: 20px 20px 20px 40px;
          border-left: none;
          margin-left: -35px;
          max-height: 168px;
          box-sizing: border-box;

          @media (max-width: $breakpointLg){
            border: none;
            padding: 0 20px;
            margin-left: 0;
            max-height: 100%;
          }

            p{
                font-size: 1rem;
                line-height: 1.375rem;
                font-family: var(--font-family-content);
                padding:0;
            }
        }

        .contentWrapNoFolder {
          display: flex;
          padding: 0 50px 0 80px;
          font-family: var(--font-family-content);
          align-items: flex-start;

          @media (max-width: $breakpointLg){
            padding: 0 20px;
            flex-wrap: wrap;
          }

          p{
            border: 1px solid var(--neutral900);
            padding: 16px 16px 22px 16px;
            margin:0;
            border-radius: 12px;
            margin-right: 26px;

            @media (max-width: $breakpointLg){
              margin: 0 0 20px 0;
              font-size: 0.875rem;
            }
          }

          .listWrap{
            width: 100%;
            max-height: 390px;
            background-color: var(--neutral100);
            border-radius: 12px;
            padding: 16px 16px 22px 16px;
            box-sizing: border-box;
            max-width: 390px;
            min-width: 390px;

            @media (max-width: $breakpointLg){
              max-width: 100%;
              min-width: auto;
            }

            h6{
              font-family: var(--font-family-content);
              font-size: 1.5rem;
              margin: 0 0 10px 0;
              font-weight: 500;
              
              @media (max-width: $breakpointLg){
                font-size: 1.2rem;
              }
            }
          }

          ul{
            margin: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 10px;

            li{
              list-style: none;
              position: relative;
              padding-left: 15px;
              @media (max-width: $breakpointLg){
                font-size: 0.875rem;
              }

              &::after{
                content: url(../../assets/li1.svg);
                position: absolute;
                left: 0;
                top: -1px;
              }


              &:nth-child(2){
                &::after{
                  content: url(../../assets/li2.svg);
                }
              }

              &:nth-child(3){
                &::after{
                  content: url(../../assets/li3.svg);
                }
              }

            }
          }
        }
  
        &.active {
          max-height: 600px; 
          padding: 2rem 0 1rem 0; 
          transition: all 0.25s ease-in-out;
        }
      }
    }
  }
  
$breakpointL: 768px;
$breakpointLg: 992px;
$breakpointXl: 1250px;

.About {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top:127px;

    @media (max-width: $breakpointLg){
        padding-top:40px;
    }    
   
    .bannerImg{
        box-sizing: border-box;
        height: 100vh;
        max-height: 702px;
        display: flex;
        align-items: center;
        padding-left: 135px;
        width: 100%;
        max-width: var(--content-width-large);
        position: relative;
        background-image: url(../../assets/about-header.png);
        background-repeat: no-repeat;
        background-position: right;
        background-size: contain;

        @media (max-width: $breakpointL){
            padding: 0;
            flex-direction: column;
            height: auto;
            background-image: url(../../assets/about-header-mobile.png);

        }

        @media( max-width: $breakpointXl){
        .overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            background: linear-gradient(to top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
            }
    
        @media (max-width: $breakpointL){
            position: relative;
        }
        }

        h1 {
            font-size: var(--h1-font-size);
            line-height: var(--h1-line-height);
            max-width: 800px;
            width: 100%;
            color: var(--neutral900);
            font-weight: 600;
            z-index: 1;
            position: relative;

            @media (max-width: $breakpointL){
                box-sizing: border-box;
                padding: 180px 20px 0 ;
                bottom:0;
            }
        }
    
    }

    .bannerText{
        width: 100%;
        max-width: 991px;
        padding: var( --layout-padding);
        box-sizing: border-box;
        padding-top: 90px;
        margin-bottom: 100px;
        position: relative;

        @media (max-width: $breakpointXl ){
            margin-bottom: 70px;
            margin-top: 50px;
            padding-left:40px;
        }

        h1 {
            font-size: 1.875rem;
            text-transform: uppercase;
            font-weight: 400;
            color: var(--neutral900);
            padding-left:20px;
            position: relative;

            @media (max-width: $breakpointL){
                font-size: 1.25rem;
            }

            &::after{
                content: url(../../assets/arrow-about.svg);
                position: absolute;
                left:0;
                bottom:-30px;

                @media (max-width: $breakpointL){
                    content: url(../../assets/about-mobile-arrow.svg);
                }
            }
        }

        p{
            font-size: var(--h2-font-size);
            line-height: var(--h2-line-height);
            font-weight: 500;
            color: var(--neutral900);
            
            span{
                color: var(--accent-color-blue);
            }
        }
    }

    .headerSlider {
        width: 100%;
        background-color: var(--neutral900);
        border-bottom: 1px solid var(--white);
    }

    .textContentWrap {
        padding: 116px 20px 100px;
        background-color: var(--neutral900);
        width: 100%;
        display: flex;
        justify-content: center;
        box-sizing: border-box;

        @media (max-width: $breakpointXl ){
            padding: 70px 20px;
        }

        .text{
            width: 100%;
            max-width: var(--content-width);
            border: 1px solid var(--white);
            border-radius: 12px 12px 0 0 ;
            display: flex;

            @media (max-width: $breakpointLg){
                flex-wrap: wrap;
            }

            .imgWrap {
                background-image: url(../../assets/about-patternt.svg);
                width: 287px;
                background-repeat: no-repeat;
                background-size: cover;
                height: inherit;
                border-right: 1px solid var(--white);

                @media (max-width: $breakpointLg){
                    height: 184px;
                    width: 100%;
                    border: none;
                    border-bottom: 1px solid var(--white);
                }
            }

            .innerText {
                width: 100%;
                padding: 20px;

                p{
                    margin: 0;
                    font-size: 1.5rem;
                    color: var(--white);
                    font-family: var(--font-family-content);

                    @media (max-width: $breakpointLg){
                        font-size: 1rem;
                    }
                }
               
            }
        }

        .footer{
            background-image: url(../../assets/about-footer-bg.svg);
            background-position: top;
            background-repeat: repeat-x;
            border: 1px solid white;
            margin-top: -1px;
            border-radius: 0 0 12px 12px;
            display: flex;
            align-items: center;
            padding:0 40px 0 0 ;

            @media (max-width: $breakpointLg){
                flex-wrap: wrap;
                text-align: center;
                justify-content: center;
                padding: 25px;
            }

            p{
                font-family: var(--font-family-content);
                font-size: 2rem;
                color: var(--accent-color-blue);
                text-transform: uppercase;
                font-weight: 700;
                margin:0; 
                padding: 20px 40px;

                @media (max-width: $breakpointLg){
                    font-size: 1.5rem;
                    padding:0;
                    margin-bottom: 20px;
                }
            }

            
            a {
                padding: 15px 60px;
                display: flex;
                border:1px solid var(--neutral900);
                background-color: var(--accent-color-blue);
                align-items: center;
                justify-content: center;
                border-radius: 24px;
                max-width: 90px;
                transition: all 0.2s ease-in-out;

                &:hover {
                    opacity: 0.86;
                    transition: all 0.2s ease-in-out;
                }
            }
            
        }
    }

    .accordionWrap{
        h1{
            font-size: var(--h1-font-size);
            line-height: var(--h1-line-height);
            color: var(--neutral900);
            font-weight: 600;

            @media (max-width: $breakpointXl ){
                margin-top: 0;
            }
        }

        width: 100%;
        max-width: var(--content-width);
        padding: 150px 20px 130px;
        box-sizing: border-box;

        @media (max-width: $breakpointXl ){
            padding: 70px 20px;
        }
    }

    $duration: 4s;

    .loading {
        position: absolute;
        z-index: 1;
        top: -121px;
        left: -78px;
        width: 200px;
        height: 1px;
        transform: rotate(90deg);

        @media (max-width: $breakpointL ){
            top: - 5px;
            left: -17px;
            width: 118px;
        }
    }

    .loadingLineWrapper {
        width: 120%;
        height: 3px;
        position: relative;
        top: 0px;
        left: 0px;
        bottom: auto;
        right: auto;

        @media (max-width: $breakpointLg){
            top: -20px
        }

        @media (max-width: $breakpointL){
            top: 0;
        }
    }

    .loadingLine {
        position: relative;
        top: 0px;
        left: 0px;
        bottom: auto;
        right: auto;
        width: 100%;
        height: 100%;
        transform-origin: 100% 0%;
        animation: kfLoadingLine $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;

    }

    .loadingLineInner1 {
        opacity: 0;
        animation: kfLoadingLineInner1 $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
    }

    .loadingLineInner2 {
        opacity: 1;
        animation: kfLoadingLineInner2 $duration cubic-bezier(0.645, 0.045, 0.355, 1) 0s infinite;
    }

    .loadingLineInner {
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: auto;
        right: auto;
        width: 100%;
        height: 100%;
        background: #000;
        transform-origin: 0% 0%;
    }

    @keyframes kfLoadingLine {
    0% {
        transform: scaleX(1);
    }
    50% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(0);
    }
    }

    @keyframes kfLoadingLineInner1 {
    0% {
        transform: scaleX(0);
    }
    25% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(1);
    }
    }

    @keyframes kfLoadingLineInner2 {
    0% {
        transform: scaleX(0);
    }
    25% {
        transform: scaleX(0);
    }
    50% {
        transform: scaleX(1);
    }
    100% {
        transform: scaleX(1);
    }
    }

}
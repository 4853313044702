.NotFound {
    padding: 170px 0 110px;
    display: flex;
    justify-content: center;    
    width: 100%;
    background-color: var(--neutral900);

    .NotFoundWrapper {
        width: 100%;
        max-width: 803px;
        margin:0 auto;
        min-height: 500px;
        background-image: url(../../assets/404.png);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        flex-direction: column;
        align-items: center;

        p{
            font-size: 24px;
            color: var(--neutral50);
            margin: 50px 0 30px;
        }

        span{
            font-size: 76px;
            color: var(--white);
            font-weight: bold;
        }

        a{
            font-size: 16px;
            text-decoration: none;
            width: 154px;
            height: 54px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 42px;
            color: var(--white);
            background-color: var(--accent-color-blue);
            transition: all 0.2s ease-in-out;

            &:hover{
                opacity: 0.86;
                transition: all 0.2s ease-in-out;
            }
        }

        div{
            font-size: 114px;
            color: var(--white);
            font-weight: bold;
            animation: glitch 1s linear infinite;
            margin-bottom: 80px;
          }
          
          @keyframes glitch{
            2%,64%{
              transform: translate(2px,0) skew(0deg);
            }
            4%,60%{
              transform: translate(-2px,0) skew(0deg);
            }
            62%{
              transform: translate(0,0) skew(5deg); 
            }
          }
          
          div:before,
          div:after{
            content: attr(title);
            position: absolute;
            left: 0;
          }
          
          div:before{
            animation: glitchTop 1s linear infinite;
            clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
            -webkit-clip-path: polygon(0 0, 100% 0, 100% 33%, 0 33%);
          }
          
          @keyframes glitchTop{
            2%,64%{
              transform: translate(2px,-2px);
            }
            4%,60%{
              transform: translate(-2px,2px);
            }
            62%{
              transform: translate(13px,-1px) skew(-13deg); 
            }
          }
          
          div:after{
            animation: glitchBotom 1.5s linear infinite;
            clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
            -webkit-clip-path: polygon(0 67%, 100% 67%, 100% 100%, 0 100%);
          }
          
          @keyframes glitchBotom{
            2%,64%{
              transform: translate(-2px,0);
            }
            4%,60%{
              transform: translate(-2px,0);
            }
            62%{
              transform: translate(-22px,5px) skew(21deg); 
            }
          }
    }
}
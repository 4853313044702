$breakpointLg: 992px;

.headerWrapper {
    width: 100%;
    background-color: var(--neutral900);
    display: flex;
    justify-content: center;
}

.textWrap {
    padding: 120px 20px 90px;
    display: flex;
    align-items: center;

    @media (max-width: $breakpointLg){
        padding: 70px 20px;
  
        h2{
            margin: 0;
        }
      }
}
.accordionWrap {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 100px;
    max-width: var(--content-width);
    padding: var(--layout-padding);

    @media (max-width: $breakpointLg){
        margin-bottom: 70px;
    }
}
.accordionWrap {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 100px;
    max-width: var(--content-width);
    padding: var(--layout-padding);

    @media (max-width: $breakpointLg){
        margin-bottom: 70px;
    }
}
.scrollToTopButton {
    position: fixed;
    bottom: 30px;
    right: 30px;
    cursor: pointer;
    z-index: 100;
    transition: opacity 0.3s ease;
    display: flex;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: var(--accent-color-dark-blue);

        &::after{
            content: "";
            height: 15px;
            width: 15px;
            border-left: 2px solid var(--white);
            border-top: 2px solid var(--white);
            position: absolute;
            margin-top:4px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) rotate(45deg);
        }
    

    &.hidden {
        opacity: 0;
        pointer-events: none;
      }
  }
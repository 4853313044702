$breakpointSm: 576px;
$breakpointLg: 992px;
$breakpointXl: 1250px;

.WayFoWork {
    background: var(--neutral900);
    display: flex;
    justify-content: center;
    width: 100%;

    .wrapper{
        margin: 0 0 -70px 0;
        z-index: 1;
        padding: 170px 0 0;
        max-width: 973px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        @media (max-width: $breakpointLg){
          padding: 95px 0 0;
        }

        .steps {
          position: relative;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-direction: column;
          padding: 20px 15px;
          border-right: 1px solid var(--neutral900);
          border-right: 1px solid var(--white);

          @media (max-width: $breakpointLg){
            flex-direction: row;
            border-top: 1px solid var(--white);
            border-radius: 0 12px 0 0 ;
            margin-top: -1px;
            width: calc(100% - 40px);
            margin-left:10px;
          }

          p{
            text-wrap: nowrap;
            padding: 0;
            writing-mode: vertical-lr;
            transform: rotate(180deg);
            margin: 15px 0  0 0;
            color: var(--white);

            @media (max-width: $breakpointLg){
              writing-mode: unset;
              transform: none;
              margin: 0;
              color: var(--accent-color-green)
            }
          }
          div{
              width: 45px;
              height: 45px;
              border-radius: 50%;
              border: 1px solid var(--neutral900);
              display: flex;
              align-items: center;
              justify-content: center;
              background: var(--neutral300);

              img{
                transition: all 0.2s ease-in-out;
              }

              @media (max-width: $breakpointLg){
                width: 35px;
                height: 35px;
                margin-right: 25px;
              }
          }
        }

        .buttonWrap{
          display: flex;
          justify-content: flex-end;

          button {
            padding: 15px 28px;
            display: flex;
            border:1px solid var(--neutral900);
            background-color: var(--accent-color-blue);
            align-items: center;
            justify-content: center;
            border-radius: 24px;
            max-width: 90px;
            transition: all 0.2s ease-in-out;
            cursor: pointer;

            &:hover {
                opacity: 0.86;
                transition: all 0.2s ease-in-out;
            }
          }
        }

        .block1 {
            box-sizing: border-box;
            padding: 30px;
            width: 100%;
            max-width: 600px;
            height: 169px;
            border-left: 1px solid var(--white);
            border-bottom: 1px solid var(--white);
            border-radius: 0 0 0 12px;
            display: flex;
            align-items: flex-end;
            align-self: flex-end;
            margin-right: 130px;
            

            h1{
                font-size: var(--h1-font-size);
                line-height: var(--h1-line-height);
                color: var(--white);
                font-weight: 600;
                margin:0;
            }

            @media (max-width: $breakpointLg){
              max-width: calc(100% - 50px);
              height: auto;
              margin: 0 30px 0 20px;
            }
        }

        .block2{
            width: 100%;
            max-width: 852px;
            padding:50px 0 70px 30px;
            box-sizing: border-box;
            position: relative;
            display: flex;
            justify-content: flex-end;
            padding-right: 80px;
            align-self: flex-start;

            &::after{
                content: url('../../assets/wayOfWork.svg');
                position: absolute;
                width: 80px;
                border:1px solid var(--white);
                border-radius: 0 12px 12px 0;
                right: -21px;
                top:-1px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            p {
                font-size: 1.5rem;
                color: var(--white);
                font-weight: 500;
                margin: 0;
                max-width: 500px;
            }

            @media (max-width: $breakpointLg){
              max-width: calc(100% - 50px);
              height: auto;
              margin: 0 20px 0 30px;
              border: 1px solid var(--white);
              border-left:none;
              border-radius: 0 12px 12px 0;
              padding: 20px 20px 20px 0;
              margin-top: -1px;

              &::after{
                display: none;
              }

              p{
                max-width: 100%;
                padding: 0 20px;
                font-size: 1rem;
                line-height: 140%;
              }
            }
        }

        .block3{
            border: 1px solid var(--white);
            border-right: none;
            border-radius: 12px 0 0 12px;
            padding: 24px 0 24px 12px;
            box-sizing: border-box;
            width: 100%;
            max-width: 863px;

            @media (max-width: $breakpointLg){
              max-width: calc(100% - 50px);
              margin: -1px 30px 0 20px;
              padding: 20px 0 20px 20px;
            }

            .tabMobileButtons{
              display: none;
    
              @media (max-width: $breakpointSm){
                display: flex;
                justify-content: space-between;
                padding-bottom: 20px;
                align-items: center;
                width: 100%;
                
                h6{
                  font-size: 1rem;
                  font-weight: 400;
                  margin: 0;
                  color: var(--accent-color-green)
                }
    
                > div {
                  display: flex; 
                  align-items: center;
    
                }
      
                button {
                  width: 45px;
                  height: 45px;
                  border-radius: 50%;
                  border: 1px solid var(--neutral900);
                  align-items: center;
                  justify-content: center;
                  background-color: var(--white);
                  cursor: pointer;
                  transition: all 0.3s ease-in-out;
      
                  &:hover{
                    opacity: 0.75;
                    transition: all 0.3s ease-in-out;
                  }
    
                  &:disabled{
                    background-color: var(--neutral200);
                    opacity: 0.5;
    
                      &:hover{
                        background-color: var(--neutral200);
                      }
                    }
                  
      
                  &.prev{
                    margin-right:20px;
                    transform: rotate(90deg);
                  }
      
                  &.next{
                    transform: rotate(-90deg);
                  }
                }
              }
    
    
            }


            .tabsContainer {
              min-width: 620px;
              width: 100%;
              padding: 30px;
              height: 285px;
              box-sizing: border-box;
              background-color: var(--white);
              border-radius: 16px;
              margin-left:12px;
    
              @media (max-width: $breakpointXl) {
                min-width: auto;
                height: auto;
                width: calc(100% + 10px);
              }

              @media (max-width: $breakpointLg){
                margin-left: 0;
              }
        
              .tabs {
                display: flex;
                margin-bottom: 24px;
                justify-content: space-between;
                position: relative;
        
                @media (max-width: $breakpointSm){
                  display: none;
                }
    
                &::after {
                  content: '';
                  width: 100%;
                  position: absolute;
                  height: 1px;
                  background-color: var(--neutral900);
                  top: 50%;
                  z-index: 0;
                }
              }
    
              .tab {
                cursor: pointer;
                width: 45px;
                height: 45px;
                border-radius: 50%;
                border: 1px solid var(--neutral900);
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--neutral900);
                background-color: var(--white);
                outline: 8px solid var(--white);
                z-index: 1;
                transition: all 0.2s ease-in-out;
        
                &:hover {
                  background-color: var(--accent-color-blue);
                  transition: all 0.2s ease-in-out;
                }
              }
              
              .tab.active {
                background-color: var(--accent-color-blue);
              }
              
              .tabContent {
    
                @media (max-width: $breakpointSm){
                  > div{
                    display: flex;
                    align-items: center;
                    margin-bottom: 15px;
                  }
                }
    
                span{
                  display: none;
                    @media (max-width: $breakpointSm){
                      width: 45px;
                      height: 45px;
                      border-radius: 50%;
                      border: 1px solid var(--neutral900);
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      color: var(--neutral900);
                      background-color: var(--accent-color-blue);
                      margin-right: 20px;   
                    }          
                }
    
                h6{
                  font-size: 1.5rem;
                  font-weight: 500;
                  font-family: var(--font-family-content);
                  margin: 0 0 16px 0;
    
                  @media (max-width: $breakpointSm){
                    width: calc(100% - 65px);
                    margin: 0;
                  }
                }
                p{
                  margin: 0 0 25px 0;
                  font-family: var(--font-family-content);

                  @media (max-width: $breakpointSm){
                    font-size: 0.875rem;
                  }
                }
              }
            }
        }

        .block4{
          margin-left:10px;
          width: 962px;
          border: 1px solid var(--white);
          display: flex;
          justify-content: flex-end;
          padding: 25px;
          box-sizing: border-box;
          border-radius: 0 12px 12px 0;
          margin-top: -1px;
          border-left:0;

          @media (max-width: $breakpointLg){
            display: none;
          }

          img{
            height: auto;
            max-width: 100%;
          }
        }

        .block5{
          margin-right: 10px;
          width: 962px;
          border: 1px solid var(--white);
          border-radius: 12px 0 0 12px;
          border-right: 0;
          margin-top: - 1px;
          display: flex;

          @media (max-width: $breakpointLg){
            width: calc(100% - 40px);
            margin: 0 20px 0 20px;
            border: none;
            flex-direction: column;
          }

          .products {
            padding: 17px;
            box-sizing: border-box;
            
            @media (max-width: $breakpointLg){
              width: calc(100% - 10px);
              margin-left: 11px;
              border: 1px solid var(--white);
              border-left: none;
              margin-right: -1px;
              padding: 20px;
              border-radius: 0 0 12px 0;
            }

            h2{
              font-size: 1.5rem;
              color: var(--accent-color-blue);
              font-family: var(--font-family-content);
              margin-left: 15px;
              margin-top:0;
            }

            ul{
              margin: 0;
              padding: 0;

              a{
                text-decoration: none;
                transition: all 0.2s ease-in-out;

                &:hover {
                  opacity: 0.86;
                  transition: all 0.2s ease-in-out;
                }
              }

              li{
                padding: 10px 15px;
                list-style: none;
                font-size: 1.5rem;
                color: var(--white);
                font-family: var(--font-family-content);
                border-top:1px solid var(--white);
                border-radius: 17px;
                margin-bottom: 10px;
              }
            }
          }
        }

        .block6{
          margin-left:10px;
          width: 962px;
          border: 1px solid var(--white);
          display: flex;
          justify-content: flex-end;
          box-sizing: border-box;
          border-radius: 0 12px 12px 0;
          margin-top: -1px;
          border-left:0;

          @media (max-width: $breakpointLg){
            width: calc(100% - 50px);
            margin: -1px 20px 20px;
            border: 1px solid var(--white);
            border-right: 0;
            border-radius: 12px 0 0 12px;
          }

          .content{
            margin: 25px;
            width: 100%;
            max-width: 610px;
            padding: 15px 32px;
            background-color: var(--white);
            border-radius: 12px;
            font-family: var(--font-family-content);
            color: var(--neutral900);

            @media (max-width: $breakpointLg){
              max-width: calc(100% + 10px);
              margin: 20px -10px 20px 20px;
            }
          }

          .pattern {
            text-wrap: nowrap;
            display: flex;
            align-items: center;
            justify-content: center;
            writing-mode: vertical-lr;
            overflow: hidden;
            padding: 25px 30px;
            border-left: 1px solid var(--white);
            color: var(--white);
            line-height: 140%;
            font-family: var(--font-family-content);

            @media (max-width: $breakpointLg){
              display: none;
            }
          }

        }

        .block7{
          margin-right:10px;
          width: 962px;
          border: 1px solid var(--white);
          border-radius: 12px 0 0 12px;
          border-right: 0;
          margin-top: - 1px;
          display: flex;
          justify-content: flex-start;

          @media (max-width: $breakpointLg){
            width: calc(100% - 40px);
            margin: -20px 20px 0 20px;
            border: none;
            flex-direction: column;
          }

          .content {
            width: 100%;
            max-width: 460px;
            padding: 40px 32px;
            font-family: var(--font-family-content);
            box-sizing: border-box;

            @media (max-width: $breakpointLg){
              width: calc(100% - 10px);
              max-width: 100%;
              margin-left: 11px;
              border: 1px solid var(--white);
              border-left: none;
              margin-right: -1px;
              padding: 20px;
              border-radius: 0 0 12px 0;
            }

            h2{
              color: var(--white);
            }
            p{
              color: var(--white);
            }
          }
        }

        .block8{
          margin-left:10px;
          width: 962px;
          border-top: 1px solid var(--white);
          border-right: 1px solid var(--white);
          display: flex;
          justify-content: flex-end;
          box-sizing: border-box;
          border-radius: 0 12px 0 0;
          margin-top: -1px;
          border-left:0;
          height: 250px;

          @media (max-width: $breakpointLg){
            height: 82px;
            width: calc(100% - 50px);
            border-right: none;
            border-left: 1px solid var(--white);
            border-radius: 12px 0 0 0;
            margin: -1px 19px -12px;
          }
        }
    }
    
}